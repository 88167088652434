export interface CustomValidatorInterface {
  htmlId: string;
  validate(): boolean;
  message: string;
}

export interface CustomErrorMessagesInterface {
  [htmlId: string]: string;
}

export interface CustomValidationResultsInterface {
  [htmlId: string]: boolean;
}

export class CustomValidator {
  private validators: CustomValidatorInterface[] = [];

  private errorMessages: CustomErrorMessagesInterface = {};

  private validationResults: CustomValidationResultsInterface = {};

  public initErrorMessages(obj: CustomErrorMessagesInterface): void {
    this.errorMessages = obj;
  }

  public addValidator(validator: CustomValidatorInterface): void {
    this.validators.push(validator);
  }

  public setValidators(validators: CustomValidatorInterface[]): void {
    this.validators = validators;
  }

  public getValidators(): CustomValidatorInterface[] {
    return this.validators;
  }

  public getErrorMessages(): CustomErrorMessagesInterface {
    return this.errorMessages;
  }

  public getValidationResults(): CustomValidationResultsInterface {
    return this.validationResults;
  }

  public getValidationResult(htmlId: string): boolean {
    return this.validationResults[htmlId] || true;
  }

  public getTotalValidationResult(): boolean {
    return Object.keys(this.validationResults).reduce((accumulator: boolean, currentKey: string) => accumulator && this.validationResults[currentKey], true);
  }

  public getErrorMessage(htmlId: string): string|null {
    return this.errorMessages[htmlId] || null;
  }

  public validate(): void {
    this.validators.forEach((item) => {
      this.validateField(item);
    });
  }

  public validateById(htmlId: string): void {
    const validator = this.validators.find((item) => item.htmlId === htmlId);

    if (validator) {
      this.validateField(validator);
    }
  }

  private validateField(validator: CustomValidatorInterface): void {
    const element = document.getElementById(validator.htmlId);
    const result = validator.validate();

    this.errorMessages[validator.htmlId] = !result ? validator.message : '';
    this.validationResults[validator.htmlId] = result;

    if (element) {
      if (result) {
        element.classList.remove('error-field');
      } else {
        element.scrollIntoView({ behavior: 'smooth' });
        element.classList.add('error-field');
      }
    }
  }
}
