import { WatchOptions } from 'vue';

export default {
  priceRequestTarif: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'tarif');
      }
    },
    deep: true,
  },
  priceRequestVariant1: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'variant1');
      }
    },
    deep: true,
  },
  priceRequestVariant2: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'variant2');
      }
    },
    deep: true,
  },
  priceRequestVariant3: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'variant3');
      }
    },
    deep: true,
  },
} as Record<string, any>;

