


















import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default Vue.extend({
  props: {
    icon: {
      type: String,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    placeholder: {
      type: String,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    type: {
      type: String,
      default: 'text',
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    namespace: {
      type: String,
      default: 'zuersZoneModule',
    },
  },
  computed: {
    isZursSelected(): boolean {
      const validValues = [1, 2, 3, 4];
      return validValues.includes(this.zurs);
    },
    ...mapState({
      zurs(state: any) {
        return state[this.namespace].zurs;
      },
      apiResponseStatus(state: any) {
        return state[this.namespace].apiResponse.status;
      },
    }),
    isFieldDisabled(): boolean {
      return this.zurs !== -1;
    },
    hasApiResponse(): boolean {
      return this.apiResponseStatus !== '';
    },
  },
  data: () => ({
    name: 'zurs',
    activeValue: -1,
    options: [
      {
        label: 'unbekannt',
        value: -1,
      },
      {
        label: 1,
        value: 1,
      },
      {
        label: 2,
        value: 2,
      },
      {
        label: 3,
        value: 3,
      },
      {
        label: 4,
        value: 4,
      },
    ],
  }),
  methods: {
    saveAddress() {
      this.$store.dispatch(`${this.namespace}/saveAddress`);
    },
    setZurs(value: number) {
      this.$store.commit(`${this.namespace}/SET_ZURS`, value);
    },
  },
});
